<template>
    <ul>
        <li v-if="product.price > 0">
            <img
                :src="assetUrl('/static/img/icon-price.svg')"
                alt=""
                aria-hidden="true"
            />
            <product-price :price="product.price"></product-price>
        </li>
        <li>
            <gift-icon class="w-6"></gift-icon>
            <span>Gift</span>
        </li>
    </ul>
</template>

<script setup>
import { assetUrl } from '@/cdn';
import { GiftIcon } from '@heroicons/vue/outline';
import ProductPrice from 'components/Global/ProductPrice';
const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
});
</script>

<style scoped>
:deep(.price .shipping-price) {
    @apply !text-sm;
}

ul li {
    @apply flex items-center space-x-2 text-sm font-medium;
}
</style>
