<template>
    <div :style="{ width: width, padding: padding }"></div>
</template>
<script setup>
const props = defineProps({
    width: {
        type: String,
        required: false,
        default: '50px',
    },
    padding: {
        type: String,
        required: false,
        default: '8px',
    },
});
</script>
<style scoped>
div {
    aspect-ratio: 1;
    border-radius: 50%;
    background: theme('colors.blue.DEFAULT');
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    to {
        transform: rotate(1turn);
    }
}
</style>
