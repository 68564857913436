<template>
    <div
        class="at768:max-h-hero"
        :class="{
            relative: !!video,
        }"
    >
        <span class="gallery-image" v-html="image"></span>
        <a
            v-if="!!video"
            :href="video"
            class="group absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2"
        >
            <span class="hidden">Play</span>
            <!-- This is not a heroicon SVG -->
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="h-16 w-16 fill-white group-hover:fill-orange at1024:h-32 at1024:w-32"
                aria-hidden="true"
            >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-2.5-3.5 7-4.5-7-4.5v9z"
                ></path>
            </svg>
        </a>
    </div>
</template>

<script setup>
const props = defineProps({
    image: {
        type: String,
        required: true,
    },
    video: {
        type: String,
        required: false,
        default: () => '',
    },
});
</script>

<style scoped>
.gallery-image :deep(img) {
    @apply h-full w-full rounded-lg object-cover object-center;
}
</style>
