<template>
    <info-modal>
        <div class="flex flex-col space-y-6">
            <header class="flex flex-row items-center space-x-4 text-left">
                <badge-check-icon class="h-8 w-8 stroke-1"></badge-check-icon>
                <h3 class="font-semibold">Elevent assurance</h3>
            </header>

            <main class="space-y-6 text-sm">
                <p>
                    Elevent Assurance is comprehensive protection included for
                    free with every booking.
                </p>
                <div class="flex flex-col space-y-1.5">
                    <h5>Booking Protection Guarantee</h5>
                    <p>
                        In the unlikely event that a Host needs to cancel your
                        event, we'll find you a similar or better event &mdash;
                        or we'll refund you.
                    </p>
                </div>
                <div class="flex flex-col space-y-1.5">
                    <h5>Get-What-You-Booked Guarantee</h5>
                    <p>
                        If at any time during your event, you find it isn't as
                        advertised &mdash; for example, the kits don't include
                        the items described &mdash; you'll have up to three days
                        after the event date to report it and we'll make it
                        right.
                    </p>
                </div>
                <div class="flex flex-col space-y-1.5">
                    <h5>Happy Guest Guarantee</h5>
                    <p>
                        If after your event you or your group are unhappy for
                        any reason we will work with you to make it right.
                    </p>
                </div>
                <div class="flex flex-col space-y-1.5">
                    <h5>Live Chat Support and Help</h5>
                    <p>
                        If you or your team has any issues joining on the day of
                        the event or with coordination before the event, our
                        team is here to help troubleshoot and resolve the issue.
                    </p>
                </div>
            </main>
        </div>
    </info-modal>
</template>

<script setup>
import { BadgeCheckIcon } from '@heroicons/vue/outline';
import InfoModal from 'components/Global/InfoModal';
</script>

<style scoped>
h5 {
    @apply font-semibold;
}
</style>
